import api from '@js/actions/api';

export const fetchStats = () => {
    if (process.env.NODE_ENV === 'production') {
        return api.get('/api/v1/stats');
    } else {
        return Promise.resolve({
            computed_distance: 0
        });
    }
};
